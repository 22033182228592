import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Row, Col, Button } from 'antd'
import AnchorLink from 'react-anchor-link-smooth-scroll'

import logo from '../../static/logo.png'

const StyledHeader = styled.div`
  width: 100%;
  position: fixed;
  z-index: 10;
  transition: 0.4s;
  background-color: ${props => props.theme.whitePalette.original};
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
`

const StyledLogo = styled.div`
  img {
    cursor: pointer;
    padding: 10px 0px 10px 14px;
    height: 64px;
    background-color: white;
    float: left;
    margin-bottom: 0;
  }
`

const HeaderComponent = ({ location, headerstyle }) => {
  const [scrollTop, setScrollTop] = useState(0)

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener('scroll', onScroll)

    return () => window.removeEventListener('scroll', onScroll)
  }, [scrollTop])

  return headerstyle !== 'none' ? (
      <StyledHeader scrolling={scrollTop} headerstyle={headerstyle}>
        <Row type="flex" align="middle" justify="space-between">
          <Col>
            <Link to="/">
              <StyledLogo>
                <img src={logo} alt="PRIME logo" />
              </StyledLogo>
            </Link>
          </Col>
          <Col style={{ textAlign: 'right', paddingRight: '14px' }}>
            <AnchorLink href="#form">
              <Button type="primary" ghost>
                Subscribe Now
              </Button>
            </AnchorLink>
          </Col>
        </Row>
      </StyledHeader>
  ) : null
}

HeaderComponent.propTypes = {
  pageLinks: PropTypes.array,
  location: PropTypes.object,
  headerstyle: PropTypes.oneOf(['default', 'fullbar', 'none']),
}

HeaderComponent.defaultProps = {
  headerstyle: 'fullbar',
  pageLinks: [],
}

export default HeaderComponent
